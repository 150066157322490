<div class="container-fluid">
  <div class="row">
    <div class="card col-lg-6 col-sm-12">
      <img class="card-img-top" src="assets/imgs/Huette/Theke1.jpg" alt="Getränkeausschank">
      <div class="card-body">
        <h5 class="card-title">Gaststätte</h5>
        <div class="card-text" id="restaurant">
          <h5>Öffnungszeiten</h5>
          <ul>
            <li>Jeden Samstag und Sonntag</li>
            <li>alle Feiertage (außer 1. und 2. Weihnachtsfeiertag und Neujahr)</li>
            <li>zusätzlich mittwochs ab Mai bis Ende Oktober</li>
            <li>"Zwischen den Jahren" täglich</li>
            <li>jeweils von 10:00 Uhr bis 18:00 Uhr<br>
              .... und bitte schenken Sie zu unseren Öffungszeiten anderen Verlautbarungen aus dem "world wide web"
              keinen Glauben.</li>
          </ul>

          <h5>Pfälzer Spezialitäten</h5>

          <p>
            Wir bieten Speisen und Getränke aus unserer Heimat an.<br>
            Seit vielen Jahren arbeiten wir mit ortsansässigen Lieferanten aus Hauenstein und Umgebung vertrauensvoll
            zusammen.<br>
          </p>
          <h5>Selbstbedienung</h5>
          <p>
            In unserem Wanderheim ist Selbstbedienung. Wir bitten Sie das gebrauchte Geschirr, Gläser und Flaschen
            zurückzubringen.
            Herzlichen Dank.
          </p>
          <h5>"... der tut ja nichts"</h5>
          <p>Ja, wir wissen das, aber Ihr vierbeiniger Freund hat leider trotzdem keinen Zutritt. Wir bitten um
            Verständnis.
          </p>
          <div class="accordion accordion-flush" id="restaurantAccordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="speisekarte">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#speisekarteCollapse" aria-expanded="false" aria-controls="speisekarteCollapse">
                  Speisen- und Getränkekarte
                </button>
              </h2>
              <div id="speisekarteCollapse" class="accordion-collapse collapse" aria-labelledby="speisekarte"
                data-bs-parent="#restaurantAccordion">
                <div class="accordion-body">
                  <img src="assets/imgs/Huette/SpeisenGetraenke1.jpg" class="card-img" alt="Speisen- Getränkekarte">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-lg-6 col-sm-12">
      <img class="card-img-top" src="assets/imgs/Huette/600x200Betten2.jpg" alt="Ein 4-Bettzimmer">
      <div class="card-body">
        <h5 class="card-title">Übernachtungen</h5>
        <div class="card-text" id="accomodation">
          <p>
            Wir verfügen über 34 Betten in sieben Mehrbettzimmern.<br>
            Die Preise verstehen sich einschließlich Bettwäsche, Benutzung der Küche und Gemeinschaftsräume.
          </p>
          <p>Hinweis<br>
            Die Zufahrt zum Wanderheim ist für Fahrzeuge aller Art gesperrt. Wir bitten Sie:
            schnüren Sie die Wanderstiefel und wandern einen der schönen Wanderwege zum Wanderheim.
            Pro Gruppe kann über die Verbandgemeindeverwaltung Hauenstein im <a target="_blank"
              href="http://www.deutsches-schuhmuseum.de">Schuhmuseum</a>
            eine gebührenpflichtige Fahrerlaubnis für PKW (Gepäcktransport) erteilt werden.
            Eine Genehmigung kann ebenfalls beim Hüttenteam, das Sie im Wanderheim empfängt, ausgestellt werden.
          </p>
          <div class="accordion accordion-flush" id="uebernachtungAccordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="schlaf">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#schlafCollapse" aria-expanded="false" aria-controls="schlafCollapse">
                  Zimmer und Betten
                </button>
              </h2>
              <div id="schlafCollapse" class="accordion-collapse collapse" aria-labelledby="schlaf"
                data-bs-parent="#uebernachtungAccordion">
                <div class="accordion-body">
                  <h5>Wir bieten Übernachtungsmöglichkeiten in 7 Zimmern:</h5>
                  <ul>
                    <li>3 Drei-Bettzimmer</li>
                    <li>3 Vier-Bettzimmer</li>
                    <li>1 Fünf-Bettzimmer</li>
                    <li>1 Zwölf-Bettzimmer</li>
                  </ul>
                </div>
                <img src="assets/imgs/Huette/2Betten600x200.jpg" class="card-img" alt="Mehrbettzimmer">
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="gemeinschaft">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#gemeinschaftCollapse" aria-expanded="false" aria-controls="gemeinschaftCollapse">
                  Gemeinschaftsräume
                </button>
              </h2>
              <div id="gemeinschaftCollapse" class="accordion-collapse collapse" aria-labelledby="gemeinschaft"
                data-bs-parent="#uebernachtungAccordion">
                <div class="accordion-body">
                  <h5>Folgende Gemeinschaftsräume stehen zur Verfügung:</h5>
                  <ul>
                    <li>Duschräume</li>
                    <li>Waschräume</li>
                    <li>Toiletten</li>
                    <li>Aufenthaltsraum mit ca. 50 Sitzplätzen</li>
                    <li>voll ausgestatte Küche</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="buchung">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#buchungCollapse" aria-expanded="false" aria-controls="buchungCollapse">
                  Buchung
                </button>
              </h2>
              <div id="buchungCollapse" class="accordion-collapse collapse" aria-labelledby="buchung"
                data-bs-parent="#uebernachtungAccordion">
                <div class="accordion-body">
                  <h5>Richten Sie bitte Ihre Buchungsanfrage an:</h5>
                  <p>
                    <a href="https://www.gruppenhaus.de/plananzeige.php?hs=7784&anzmonate=12&sprache=de&colteil=%239400d3&colbelegt=%23ff0000&plannr=1"
                      target=_blank>Belegungsplan</a>
                  </p>
                  E-Mail: <a href="mailto:buchung@pwv-hauenstein.de">buchung&#64;pwv-hauenstein.de</a><br>
                  Tel.: +49 172 71 73 976<br>
                  <p>
                    Bei Zimmerreservierung erhalten Sie weitere zu beachtenden Informationen.
                    Die Bedingungen und die Hausordnung des Wanderheims sind zur Kenntnis zu nehmen und zu beachten.</p>
                  <p>Ankunft und Abreise während den üblichen Öffnungszeiten unserer Gasstätte:</p>
                  <ul>
                    <li>Ankunft: die Einweisung erfolgt durch das Hüttenteam und ist nach dessen Arbeitsaufkommen
                      voraussichtlich spätestens ab ca. 17 Uhr möglich.</li>
                    <li>Abreise: die verantwortliche Person der Gruppe muss um 9 Uhr des Abreisetages die Abrechnung der
                      Kosten mit dem Hüttenteam vornehmen.</li>
                  </ul>
                  <p>Ankunft und Abreise außerhalb der üblichen Öffnungszeiten unserer Gaststätte:</p>
                  <ul>
                    <li>Ankunft: findet nach Absprache statt.</li>
                    <li>Abreise: findet nach Absprache statt.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="preise">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#preiseCollapse" aria-expanded="false" aria-controls="preiseCollapse">
                  Preise
                </button>
              </h2>
              <div id="preiseCollapse" class="accordion-collapse collapse" aria-labelledby="preise"
                data-bs-parent="#uebernachtungAccordion">
                <div class="accordion-body">
                  <h5>Übernachtungskosten pro Person je Nacht:</h5>
                  <ul>
                    <li>Gäste 18,00 € pro Nacht</li>
                    <li>Jugendgruppen/Schulklassen (ab 10 Jugendlichen) pro Jugendlichem bis 16 Jahren 15,00 €</li>
                    <li>Pauschalpreis für den kompletten Übernachtungsbereich: pro Nacht 550 €</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>