<div class="container-fluid">
    <div class="row">
        <div class="card col-lg-6 col-sm-12">
            <img class="card-img-top" src="assets/imgs/Home/25Wanderheim.jpg" alt="Das Wanderheim im im Winter">
            <div class="card-body">
                <h5 class="card-title">Pfälzerwald-Verein Hauenstein 1913 e.V.</h5>
                <div class="card-text">
                    <p>
                        Willkommen auf unserer Homepage <br>
                        Schauen Sie doch einfach mal wieder rein, vielleicht gibt es etwas Neues.
                    </p>

                    <h5>Ein gutes neues Jahr </h5>
                    <p>Wir wünschen Ihnen viel Glück und alles Gute im neuen Jahr. Bleiben Sie gesund.
                    </p>
                    
                    <h5>Wanderheim</h5>
                    <p>Unsere <a href="https://www.pwv-hauenstein.de/wanderheim#restaurant" target=_blank>
                            Öffnungszeiten</a>
                        Telefon Gaststätte: 06392 3596
                    </p>

                    <h5>Übernachtungen buchen</h5>
                    <p>Ihre Anfrage und Buchung können Sie gerne per E-Mail richten an:
                        <a _ngcontent-qpp-c12=""
                            href="mailto:buchung@pwv-hauenstein.de">buchung&#64;pwv-hauenstein.de</a><br>
                        Buchungs-Telefonnummer: +49 172 717 39 76<br>
                        <a href="https://www.gruppenhaus.de/plananzeige.php?hs=7784&anzmonate=12&sprache=de&colteil=%239400d3&colbelegt=%23ff0000&plannr=1"
                            target=_blank>Belegungsplan</a>
                    </p>
                    <h5>Ehrenamt</h5>
                    <p>Sie wollen uns gerne unterstützen? Sowohl das Wanderheim "Dicke Eiche" mit Gaststätte und
                        Übernachtungsbereich,
                        als auch das ganze Vereinsgeschehen wird von uns im Ehrenamt mit großem Engagement betrieben.
                    </p>
                    Möglichkeiten zum Mitwirken:
                    <ul>
                        <li>Hüttendienst</li>
                        <li>"Junge Familie"</li>
                        <li>Umwelt-/Naturschutz</li>
                        <li>Wanderbetrieb</li>
                        <li>Sie haben eigene Ideen zum Mitwirken?</li>
                    </ul>
                    <p>Es gibt viel zu tun. Sie wollen mit anpacken oder uns ideell unterstützen?
                        Melden Sie sich unter <a href="mailto:info@pwv-hauenstein.de">info&#64;pwv-hauenstein.de</a> <br>
                        Wir freuen uns auf Sie.
                    </p>
                    <h5>Auf dem Laufenden bleiben</h5>
                    Sie wollen auf dem Laufenden bleiben? Senden Sie eine E-Mail an:
                    <a _ngcontent-qpp-c12=""
                        href="mailto:waldnachrichten@pwv-hauenstein.de">waldnachrichten&#64;pwv-hauenstein.de</a>
                </div>
            </div>
        </div>

        <div class="card col-lg-6 col-sm-12">
            <img class="card-img-top" src="assets/imgs/Home/25Stephanstal.jpg" alt="Blick ins verschneite Stephanstal">
            <div class="card-body">
                <h5 class="card-title">Aktuelles</h5>
                <div class="card-text">

                    <h5>Die nächsten Wanderungen</h5>
                    <p>Samstag, 01.02.2025 11:00 Uhr, mit den Senioren: <a href="https://www.pwv-hauenstein.de/senioren#Queich-Quelle" target=_blank> Rund um die Queich-Quelle</a><br>
                       Sonntag, 09.02.2025 10:00 Uhr,  Busfahrt zum Naturkunde-Museum Bad Dürkheim. Anmeldung erforderlich bis 20.01.2025 bei Vera Klemm.
                       Weitere Infos: <a href="https://www.pwv-hauenstein.de/wanderplan#NaturkundeMuseum" target=_blank> Naturkunde-Museum</a>
                    </p>

                       <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>